@if(!loading) {
<table [dataSource]="dataSource" mat-table class="p-2 w-full min-w-full">

    <ng-container matColumnDef="service">
      <th mat-header-cell *matHeaderCellDef>Služba</th>
      <td mat-cell *matCellDef="let element">
        @if(allowEdit) {
          <mat-form-field class="flex-auto" appearance="fill" [subscriptSizing]="'dynamic'">
            <mat-select [(ngModel)]="element.name" (selectionChange)="changeType(element, $event)" required>
              @for(jobType of Object.keys(jobTypes); track jobType) {
                <mat-option [value]="jobType">{{jobTypes[jobType]}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        } @else {
          @if (jobTypes[element.name]) {
            {{jobTypes[element.name]}}
          } @else {
            {{element.name}}
          }
        }
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef> Cena </th>
      <td mat-cell *matCellDef="let element" class="p-2">
        @if(allowEdit) {
          <div class="flex flex-row items-center gap-2">
            <mat-form-field class="flex-auto" appearance="fill" [subscriptSizing]="'dynamic'">
              <mat-label>Cena</mat-label>
              <input matInput type="number" [(ngModel)]="element.price" (ngModelChange)="changePrice(element, $event)">
              <div matSuffix><div class="p-2 font-bold">Kč</div></div>
            </mat-form-field>

          </div>
        }@else {
          {{element.price | currency : 'CZK' : 'symbol' : '1.0-0'}}
        }

      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef>Přiděleno</th>
      <td mat-cell *matCellDef="let element" class="p-2">
        @if(allowEdit) {
          <canvas-user-select-job [serviceName]="element.name" [displayAll]="true" [label]="'Přidělen:'" [selectUserId]="element.userId" (user)="changeAssign(element, $event)" (price)="updateJobPrice(element, $event)" [defaultOption]="'Nevybrán'" class="flex-auto"></canvas-user-select-job>
        } @else {
          <canvas-user-collaborator-profile [id]="element.userId" [type]="'User'"></canvas-user-collaborator-profile>
        }
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="text-center items-center">Stav</th>
      <td mat-cell *matCellDef="let element">
        <div class="flex flex-row items-center place-content-center">
          @if (allowEdit) {
         <button [matMenuTriggerFor]="jobStatus" [matMenuTriggerData]="{ job: element }">
              @switch (element.status) {
                @case (0) {
                  <span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 flex items-center">
                    <mat-icon svgIcon="mat:query_builder"></mat-icon> Čeká na přidělení
                    <mat-icon svgIcon="mat:keyboard_arrow_down"></mat-icon>
                  </span>

                }
                @case (1) {
                  <span class="bg-orange-400 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300 flex items-center">
                    <mat-icon svgIcon="mat:work_history"></mat-icon> Přiděleno
                    <mat-icon svgIcon="mat:keyboard_arrow_down"></mat-icon>
                  </span>

                }
                @case (2) {
                  <span class="bg-yellow-300 text-black text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-600 dark:text-white flex items-center">
                    <mat-icon svgIcon="mat:work"></mat-icon> Zadáno
                    <mat-icon svgIcon="mat:keyboard_arrow_down"></mat-icon>
                  </span>

                }
                @case (3) {
                  <span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300 flex items-center">
                    <mat-icon svgIcon="mat:check"></mat-icon> Hotovo
                    <mat-icon svgIcon="mat:keyboard_arrow_down"></mat-icon>
                  </span>

                }
              }
          </button>

          }@else {

            @switch (element.status) {
              @case (0) {
                <span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 flex items-center">
                    <mat-icon svgIcon="mat:query_builder"></mat-icon> Čeká na přidělení
                  </span>

              }
              @case (1) {
                <span class="bg-orange-400 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300 flex items-center">
                    <mat-icon svgIcon="mat:work_history"></mat-icon> Přiděleno ({{element.datetime | date:"dd.MM.yy HH:mm"}})
                  </span>

              }
              @case (2) {
                <span class="bg-yellow-300 text-black text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-600 dark:text-white flex items-center">
                    <mat-icon svgIcon="mat:work"></mat-icon> Zadáno ({{element.datetime | date:"dd.MM.yy HH:mm"}})
                  </span>

              }
              @case (3) {
                <span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300 flex items-center">
                    <mat-icon svgIcon="mat:check"></mat-icon> Hotovo
                  </span>
              }
            }


          }
        </div>
      </td>
    </ng-container>


  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Akce </th>
    <td mat-cell *matCellDef="let element; let i = index" class="p-2">
      @if(allowEdit) {
        <button mat-icon-button (click)="removeJob(element, i)">
          <mat-icon svgIcon="mat:delete"></mat-icon>
        </button>
      }
    </td>
  </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  @if(allowEdit) {
  <div class="flex flex-row-reverse mt-1">
    <button mat-flat-button color="primary" (click)="addJob()">Přidat službu</button>
  </div>
  }
} @else {
  <div class="mt-6 flex flex-col flex-wrap items-center justify-center">
    <mat-spinner></mat-spinner>
  </div>
}

<mat-menu #jobStatus="matMenu">
  <ng-template let-job="job" matMenuContent>
  @for(jobstate of jobStatesKeys; track jobstate) {
    <button mat-menu-item (click)="changeState(job, jobstate)">{{getJobState(jobstate)}}</button>
  }
  </ng-template>
</mat-menu>
