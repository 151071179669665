import { Component, Input } from '@angular/core';
import {MatTooltipModule} from "@angular/material/tooltip";

@Component({
  selector: 'canvas-progress-circle',
  standalone: true,
  imports: [MatTooltipModule],
  template: `
    <svg viewBox="0 0 100 100">
      <!-- Gray background circle -->
      <circle
        cx="50"
        cy="50"
        r="45"
        fill="none"
        stroke="#e0e0e0"
        stroke-width="10%"
        matTooltip="Služby v procesu"
      ></circle>
      <!-- Colored progress arc -->
      <circle
        cx="50"
        cy="50"
        r="45"
        fill="none"
        stroke="currentColor"
        stroke-width="10%"
        matTooltip="Hotové služby"
        [attr.stroke-dasharray]="circumference"
        [attr.stroke-dashoffset]="dashOffset"
        style="transition: stroke-dashoffset 0.35s; transform: rotate(-90deg); transform-origin: center;"
      ></circle>
    </svg>
  `,
  styles: [`
    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  `]
})
export class ProgressCircleComponent {
  /**
   * The progress percentage (0 to 100).
   * When progress is 75, 75% of the circumference is drawn in color
   * and the remaining 25% shows the gray background.
   */
  @Input() progress = 0;

  // The circle’s radius (as used in the SVG).
  readonly radius = 45;
  // The full circumference of the circle.
  readonly circumference = 2 * Math.PI * this.radius;

  get dashOffset(): number {
    // Clamp progress between 0 and 100.
    const progressClamped = Math.max(0, Math.min(100, this.progress));
    // Calculate the offset so that the colored arc covers (progress)% of the circle.
    return this.circumference * (1 - progressClamped / 100);
  }
}
