import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  ModxClient, ServiceService,
  UserManagementService, UserWithPriceWithRelations, UserWithRelations
} from '../../../../@backend';

import {CommonModule} from "@angular/common";
import {MatSelectModule} from "@angular/material/select";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PhotoPipe} from "../../pipes/photo.pipe";
import {UserLocalService} from "../../services/userLocal.service";
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from "@angular/material/autocomplete";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {firstValueFrom, Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";

@Component({
  standalone: true,
  imports: [CommonModule, MatSelectModule, FormsModule, ReactiveFormsModule, PhotoPipe, MatInputModule, MatButtonModule, MatAutocompleteModule],
  selector: 'canvas-user-select-job',
  templateUrl: './user-select-job.component.html',
})
export class UserSelectJobComponent implements OnInit {

  @Output() user = new EventEmitter<UserWithRelations>();
  @Output() price = new EventEmitter<number>();
  @Input() serviceName?: number = null;
  @Input() selectUserId?: string | number = null;
  @Input() displayAll?: boolean = true;
  @Input() returnModxId?: boolean = true;
  @Input() label?: string = 'Klienti uživatele:';
  @Input() showRoles?: boolean = false;
  @Input() defaultOption?: string = 'Všichni uživatelé';
  @Input() defaultHint?: string | undefined = undefined;
  @Input() users?: Array<UserWithRelations> | Array<UserWithPriceWithRelations>;

  loading = true;
  filteredUsers: Observable<UserWithPriceWithRelations[]>;
  selectedUser: UserWithRelations;

  searchControl = new FormControl<UserWithRelations | string>('');

  constructor(private userLocalService: UserLocalService, private serviceService: ServiceService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['serviceName'] && !changes['serviceName'].isFirstChange()) {
      console.log(changes['serviceName'].currentValue);
      this.getCollaboratorsForService(changes['serviceName'].currentValue);
    }
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.users = await this.userLocalService.getUsers();
    if(this.selectUserId) {
       this.selectedUser = this.users.find(i => i.id === this.selectUserId);
      this.searchControl.setValue(this.selectedUser);
    }else if(this.selectUserId === 0){
      this.selectedUser = null;
      this.selectUserId = null;
    }

    this.initSearchControl();

    this.loading = false;
  }


  initSearchControl() {
    this.filteredUsers = this.searchControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  private _filter(value: string | UserWithRelations): UserWithRelations[] {
    if(typeof value !== 'string' || value === '') {
      return this.users;
    }
    return this.users.filter(user => {
      if(user?.firstname && user?.lastname) {
        return `${user.firstname} ${user.lastname}`.
          toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .includes(value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
      } else {
        return false;
      }
    });
  }


  onUserSelect(option: MatAutocompleteSelectedEvent) {
    const user = option.option.value as UserWithPriceWithRelations;
    console.log('onUserSelect', user);
    if(user) {
      this.selectedUser = user;
      this.user.emit(user);
      if(user?.price) {
        this.price.emit(user.price);
      }
    }else {
      this.selectedUser = null;
      this.user.emit(null);
    }
  }


  displayFn(user: UserWithRelations): string {
    return user ? user.firstname + ' ' + user.lastname : '';
  }

  async getCollaboratorsForService(serviceName: string) {
    console.log(`Fetching collaborators for service: ${serviceName}`);
    this.users = await firstValueFrom(this.serviceService.servicesControllerGetUsersForService(serviceName));
    this.initSearchControl();
  }


}
