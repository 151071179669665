import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {
  ItemService,
  JobWithRelations,
  UserWithRelations,
  Job
} from '../../../../@backend';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {CurrencyPipe, DatePipe, JsonPipe, NgIf} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {
  UserCollaboratorProfileComponent
} from "../../../shared/components/user-collaborator-profile/user-collaborator-profile.component";
import {jobTypes} from "../../../shared/utils/jobTypes";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {getJobState, JobStates} from "../../../shared/utils/jobStates";
import {UserSelectComponent} from "../../../shared/components/user-select/user-select.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatSelectChange, MatSelectModule} from "@angular/material/select";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialog} from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import {
  UserSelectAutocompleteComponent
} from "../../../shared/components/user-select-autocomplete/user-select-autocomplete.component";
import {UserSelectJobComponent} from "../../../shared/components/user-select-job/user-select-job.component";

@Component({
  selector: 'canvas-item-jobs',
  templateUrl: './item-jobs.component.html',
  standalone: true,
  imports: [MatTableModule, CurrencyPipe, NgIf, MatIconModule, MatProgressSpinnerModule, DatePipe, UserCollaboratorProfileComponent, MatMenuModule, MatButtonModule, UserSelectComponent, FormsModule, MatInputModule, ReactiveFormsModule, MatSelectModule, JsonPipe, UserSelectAutocompleteComponent, UserSelectJobComponent],
})
export class ItemJobsComponent implements OnInit {
  @Input() itemId: number
  @Input() jobs?: JobWithRelations[];
  @Input() allowEdit?: boolean = false;

  @Output() updatedJobs = new EventEmitter<Job[]>();

  protected readonly Object = Object;
  dataSource: MatTableDataSource<JobWithRelations> | null;
  displayedColumns: string[] = ['service', 'price', 'user', 'status'];
  loading: boolean = true;


  protected readonly jobStatesKeys = Object.keys(JobStates)
    .map((key) => {
      if (!isNaN(Number(key))) {
        return Number(key);
      }
    }).filter(key => key !== undefined);
  protected readonly getJobState = getJobState;

  protected readonly jobTypes = jobTypes;


  constructor(private itemService: ItemService, private dialog: MatDialog) {
    this.dataSource = new MatTableDataSource();
  }

  async ngOnInit() {
    this.loading = true;
    if(this.itemId === undefined && this.jobs === undefined) {
      this.jobs = [] as JobWithRelations[];
    }
    if(this.itemId && this.jobs === undefined) {
      this.jobs = await firstValueFrom(this.itemService.itemControllerGetItemJobs(this.itemId));
    }
    if(this.allowEdit) {
      this.displayedColumns.push('actions');
    }
    this.dataSource.data = this.jobs;
    this.loading = false;
  }

  changeState(job: JobWithRelations, jobState: number) {
    job.status = jobState;
    this.emitChanges();
  }

  changeAssign(job: JobWithRelations, user: UserWithRelations | any) {
    if(user) {
      job.userId = user.id;
    }else {
      job.userId = null;
    }
    this.emitChanges();
  }

  updateJobPrice(job: JobWithRelations, price: number) {
    job.price = price;
    this.emitChanges();
  }

  changeType(job: JobWithRelations, type: MatSelectChange) {
    job.name = type.value;
    this.emitChanges();
  }
  changePrice(job: JobWithRelations, price: number) {
    job.price = price;
    this.emitChanges();
  }

  removeJob(job: JobWithRelations, index: number) {

    this.dialog.open(ConfirmDialog, {
      data: {
        title: 'Smazat službu',
        message: 'Opravdu chcete smazat tuto službu?',
        confirmText: 'Smazat',
        cancelText: 'Zrušit'
      }
    }).afterClosed().subscribe(async (result) => {
      if(result) {
        if(job?.id) {
          await firstValueFrom(this.itemService.itemControllerRemoveItemJob(this.itemId, job.id));
          // Find index of job.id
          const index = this.jobs.findIndex((j) => j.id === job.id);
          // Remove job from jobs array
          this.jobs.splice(index, 1);
        }else {
          // Remove using index
          this.jobs.splice(index, 1);
        }
        this.dataSource.data = this.jobs;
        this.emitChanges();
      }
    });

  }

  emitChanges() {
      const updatedJobs = this.jobs.map((job) => {
        return {
          id: job?.id,
          picturecontractId: this.itemId ? this.itemId : null,
          contractId: job.contractId ? job.contractId : null,
          name: job.name,
          price: job.price,
          status: job.status,
          state: job.state,
          userId: job.userId,
          datetime: job?.id ? job.datetime : new Date().toISOString(),
        } as Job;
      });
    this.updatedJobs.emit(updatedJobs);
  }

  addJob() {
    this.jobs.push({
      status: 0,
      picturecontractId: this.itemId ? this.itemId : null,
    } as JobWithRelations);
    this.dataSource.data = this.jobs;
  }

}
